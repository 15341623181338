.card{
  position: relative;
  background-color: white;
}

.card::before {
  position: absolute;
  content: "";
  background-color: var(--card-decoration-color);
  width: var(--size-3);
  border-radius: var(--radius-2) 0 0 var(--radius-2) ;
  top:0;
  left: var(--size-000);
  bottom: 0;
}

.card--red{
  --card-decoration-color: var(--light-red);
}
.card--purple{
  --card-decoration-color: var(--light-purple);
}
.card--blue{

  --card-decoration-color: var(--light-blue);
}
.card--light-green{

  --card-decoration-color: var(--light-green);
}
.card--green{

  --card-decoration-color: var(--dark-green);
}

.card--black
{
  --card-decoration-color: var(--dark-gray);
}

.card-icon {
  grid-area: icon;
  display: flex;
  justify-content:  end;
}

.card-icon img {
  place-self: start end;
  width: var(--size-7);
  height: auto;
}
