.reporting-header{
    --primary-color: #307a5a;
    --secondary-color: #3498db;
    --text-color: #ffffff;
    --background-color: #ecf0f1;
    --radius-2: 0.25rem;

  padding: 1rem;
  display: grid;
  grid-template-areas: "data actions"
                       "table actions";
  gap: 0.5rem;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: var(--radius-2);
  margin-block: 1rem;
  color: var(--text-color);
  }

  .reporting-header__actions {
    display: flex;
    gap: 1rem;
    grid-area: actions;
    place-self: center end;
  }


  .mat-row:nth-child(even){
    background-color: white;
  }

  .mat-row:nth-child(odd){
    background-color: #f8f8f8;
  }

  .mat-row{
    text-align: center;
  }

  th.mat-header-cell{
  text-align: center;
  }


  .reporting-section  table {
    grid-area: table;
    width: 100%;
  }

  .reporting-section > main{
    height: 400px;
    max-width: 100%;
    overflow: auto;
  }
  .mat-dialog-actions {
    border-top: 1px solid
    #e0e0e0;
  }


  .header-spacer {
    display: flex;
    gap:0.5rem;
  }
