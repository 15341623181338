.uk-modal-footer{
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
.layer-switcher {
  position: unset;
  top: none;
  right: none;
  text-align: left;
  background-color: transparent;
}

.uk-input:focus
{
  outline-color: var(--light-blue);
  outline-style: solid;
  outline-width: 2px;
  color: unset;
}
.uk-textarea:focus{
  outline-color: var(--light-blue);
  outline-style: solid;
  outline-width: 2px;
}

input:not([matinput]):focus-visible {
  outline-color: var(--light-blue);
  outline-style: solid;
  outline-width: 2px;
}
.uk-nav{
  background-color: var(--dark-gray);
}
.uk-dropdown{
  background-color: var(--dark-gray);
}

.ol-full-screen {
  left: 0.5em !important;
  bottom: 3em !important;
  top: 0;
  right: 0;
}

body .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding-top: 1rem;
}


.uk-subnav {
  position: sticky;
  top: 0;
  background: white;
  z-index: 99999;
  padding-block: 1rem;
  box-shadow: var(--shadow-1);
  margin-inline: -30px;
}

.uk-modal-body {
  padding-block-start: 0;
}


label > .uk-input,
label > .uk-select,
label > .uk-textarea
{
  margin-top: 0.5em;
}

.ol-full-screen {
  left: 0.5em !important;
  bottom: 3em !important;
  top: 0;
  right: 0;
}

.sidebar{
  box-sizing: content-box;
}

.uk-progress{
  background-color: var(--gray-1);
}

.uk-progress-bar::selection {
  background:  var(--brand-color,#6DCB99);
}


.uk-progress::-moz-progress-bar { background: var(--brand-color,#6DCB99); }
.uk-progress::-webkit-progress-value { background:var(--brand-color,#6DCB99); }


.uk-section{
  background-color: var(--brand-color,#6DCB99);
}


.uk-table-striped tbody tr:nth-of-type(even)
{
    background: var(--gray-4);
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}



.mat-expansion-panel-body > *{
  width: 100%;
}

.uk-input{
  font-family: var(--font-sans);
  font-weight: var(--font-weight-7);
  color: black;
}


.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--blue-4);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--blue-7);
}


.mat-slider.mat-accent .mat-slider-track-fill, .mat-slider.mat-accent .mat-slider-thumb, .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: var(--blue-4);
}


.mat-expansion-panel-header-description{
    font-size: 1.1em;
    color: var(--gray-9);
    font-weight: 500;
}


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle
{
  border-color: var(--blue-7);
}


.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
  background-color: var(--blue-7);
}

canvas,img,svg,video {
  height: unset;
}

fieldset{
    border: unset;
    margin: 0;
    padding: 0;
}


.mat-dialog-content{
  padding: 1em !important;
}



/* -----------------------------
Switch */

.uk-switch {
  position: relative;
  display: inline-block;
  height: 34px;
  width: 60px;
}

/* Hide default HTML checkbox */
.uk-switch input {
  display:none;
}
/* Slider */
.uk-switch-slider {
  background-color: rgba(0,0,0,0.22);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 500px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
	transition-duration: .2s;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.07);
}
/* Switch pointer */
.uk-switch-slider:before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  transition-property: transform, box-shadow;
	transition-duration: .2s;
}
/* Slider active color */
input:checked + .uk-switch-slider {
  background-color: #39f !important;
}
/* Pointer active animation */
input:checked + .uk-switch-slider:before {
  transform: translateX(26px);
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
  background-color: #f0506e;
}
input:checked + .uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
input:checked + .uk-switch-slider.uk-switch-big:before {
  transform: translateX(26px) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255,255,255,0.22);
}

mat-progress-bar{
  --mat-progress-bar-color: red;
}

mat-progress-bar .mat-progress-bar-fill::after {
  background-color: var(--mat-progress-bar-color);
}

.ol-layer canvas {
  image-rendering: -webkit-optimize-contrast;
}
