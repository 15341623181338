.suggestion:not(:empty) {
  list-style-type: none;
  padding-inline: 0;
  margin-block: 0.25em;
  border: grey solid 1px;
  height: auto;
  max-height: 500px;
  overflow: auto;
  -webkit-filter: drop-shadow(0 0 0.1rem black);
          filter: drop-shadow(0 0 0.1rem black);
}

.suggestion > li {
  cursor: pointer;
  padding: 1rem;
  background-color: white;
  border-bottom: black solid 1px;
  -webkit-transition: background-color 150ms ease;
  transition: background-color 150ms ease;
}

.suggestion :last-child {
  border-bottom: none;
}

.suggestion > li:hover {
  background-color: slategrey;
}

.esk-geocode-search {
  width: var(--size-fluid-8);
  height: auto;
  top: 3%;
  left:6%;
  position:absolute;
}

.esk-geocode-search_container {
  display: grid;
  height: 2em;
  width: 2em;
  grid-auto-flow: column;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.27);
  grid-auto-columns: 5fr 1fr;
  border-radius: 100vh;
  border: 1px solid hsl(213,40%,56%);
  overflow: hidden;
  position: relative;
  transition: width 600ms cubic-bezier(0.18, 0.89, 0.32, 1.08);
  background-color: white;
  padding: 3px;
}

.esk-geocode-search_container:not(.static):focus-within{
  width: var(--size-fluid-8);

}

.esk-geocode-search_container:not(.static):focus-within > .esk-geocode-search_button {

  background-color:hsl(213,40%,56%);
  color: #ffff;
  place-self: center end;

}

.esk-geocode-search_container:not(.static):focus-within > .esk-geocode-search_input {
  width: calc(100% - 4rem);
  opacity: 1;
  cursor: initial;
}


.esk-geocode-search_container.static > .esk-geocode-search_input
{
  opacity: 1;
}


.esk-geocode-search_input {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  opacity: 0;
  border: none;
  box-sizing: border-box;
  padding-inline: 0.75rem;
  font-size: 1.1em;
  cursor: pointer;
  line-height: 2.5rem;
}

.esk-geocode-search_input:focus {
  border: 0;
  outline:0;
}



.esk-geocode-search_button {
  position: absolute;
  place-self: center center;
  margin: 0.25rem;
  margin-block: 1rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: calc(2rem - 5px);
  height: calc(2rem - 5px);
  font-size: 1.25em;
  border-radius: 50%;
  transition: background 600ms ease-in-out;
  display: flex;
  place-items: center center;
}

.esk-geocode-search_button {
  position: absolute;
  place-self: center end;
  margin: 0.25rem;
  margin-block: 1rem;
  /* inset: 0 0; */
  border: none;
  /* outline: 1px solid blue; */
  cursor: pointer;
  background-color: transparent;
  width: calc(2rem - 5px);
  height: calc(2rem - 5px);
  font-size: 1.25em;
  border-radius: 50%;
  transition: background 600ms ease-in-out;
  display: flex;
  place-items: center center;
}

.esk-geocode-search_clear {
  border: none;
  cursor: pointer;
  background-color: white;
}

.esk-geocode-search_clear:hover
 {

  background-color: slategray;
}

.esk-geocode-search_button:hover {
  background-color: slategray;
}

.show {
  display: block;
}

.hide {
  display: none;
}
/*# sourceMappingURL=style.css.map */

/* Width of the entire scrollbar */
::-webkit-scrollbar{
  width: 12px;
}

/* Color of the tracking area */
::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 0.5rem;
  margin-top: 1px;
  margin-bottom: 1px;
  /*padding: 2px;*/
  /*background: lightgrey;*/
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;  /* color of the scroll thumb */
  border-radius: 0.5rem;        /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
  background-clip: content-box;  /* statement to make sure the element includes the border inside its width and height (PADDING)*/
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.draw-tool{
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  background-color: white;
  box-shadow: var(--shadow-2);
  border-radius:var(--radius-2);
  border: var(--border-size-2) solid var(--gray-1);
  width: var(--size-fluid-9);
  position: absolute;
  padding: var(--size-2);
  top:10%;
  right:10%;
  opacity: 0;
  gap:var(--size-2);
  transition: opacity 500ms ease-in
}

.draw-tool:not(.draw-tool--active)
{
  display: none;
}

.draw-tool_action {
  flex-shrink: 0;
  border-radius: 2px;
  margin: 0.25rem 0 0;
  color: black;
  aspect-ratio: 1;
  width: var(--size-5);
  padding: 0;
  background-color: transparent;
  transition: background-color .1s ease-in-out;
  border: none;
  cursor: pointer;
  position: relative;
}


.draw-tool_action--active {
  color: red;
}

.draw-tool_action--disabled{
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0;
}


.draw-tool--active {
  opacity: 1;
}

.draw-tool_types{
  display: flex;
  gap:var(--size-2);
}

.draw-tool_features {
  height:  var(--size-fluid-6);
  overflow-y: auto;
  border: solid 2px var(--gray-2);
}


.draw-tool_feature {
  padding: var(--size-1);
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  place-items: center;
  border-bottom: solid 1px var(--gray-2);
}

.draw-tool_feature--selected {
  background-color:  var(--blue-5);
}

.draw-tool_feature > div {
  place-self: end;
  display: grid;
  grid-auto-flow: column;

}

.draw-tool_feature > div > button {
width: var(--size-6);
aspect-ratio: 1;
place-self: end;
border:none;
background-color: transparent;
cursor: pointer;

}
.draw-tool_feature > input {
  border:none;
  border-bottom: solid black 1px;

  }

  .draw-tool_feature > input:focus {
    border:none;

    }

.draw-tool_feature > div > button:hover {
  color: var(--blue-5)
}
.draw-tool_feature:hover {
  background-color: var(--gray-3);

}
