.map-status-message
{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: -25%;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 0 0 0.25rem 0.25rem;
  font-size: 1.25em;
  width: 150px;
  transition: top 500ms ease-in-out;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  background-color: #4bb543;
  border: solid darkgreen 1px;
  z-index: 1;
}

.map-status-message--active {
  top:0;
}

.map-status-message > span {
  color: white;
}


.prop-select-tools {
  display: grid;
  grid-template-areas:
  "..." "content" "content" "..."
  "...." "actions" "actions" "...."
}

.save-property-actions{
  grid-area: actions;
  grid-auto-flow: column;
  display: grid;
  gap: 0.5rem;
}

.save-property-actions > button{
width: 100%;
}

.feature-properties {
  border: solid #506b92 1px;
  border-radius: 0.5rem;
  margin-block-end: var(--size-2);
  min-height: fit-content;
  max-height: 25%;
  background-color: white;
  filter: drop-shadow(0 0 0.1rem black);
  display:grid;
  grid-auto-flow: row;
  opacity: 1;
  transition: opacity 200ms ease-in ;
}

.feature-properties__actions{
  display: grid;
  grid-auto-flow: column;
  padding: 0.25em;
  background-color: var(--gray-7);
  border-radius: 0.3rem 0.3rem 0 0 ;
  overflow: hidden;
  margin-block-end: var(--size-1);
}

.feature-properties__actions > *{
  place-self: start;
}

.feature-properties__actions button {
  background-color: var(--gray-0);
  color: var(--gray-7);
}

.feature-properties__actions button:hover {
  background-color: var(--blue-5);
  color: var(--gray-0);
}

.feature-properties > * {
  background-color: none;
}

.feature-property {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-inline: 1.5rem;
}


.feature-property_title {
grid-column: 1;
}

.feature-property_value {
  grid-column: 2;
}

.accordion--hidden {
  opacity: 0;
  visibility: hidden;
}



.selected-property-table .attribute {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;

}

.selected-property-table .attribute > * {
  overflow: hidden;
  max-width: 100%;
  place-self:center start;
}

.selected-property-table .attribute > input {
  padding:var(--size-2);
}

.selected-property-details{
  text-align: center;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  max-height: 500px;
  overflow: scroll;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}
