
.text-danger{
  color: var(--red-7);
}

.text-primary{
  color: var(--blue-7);
}

.text-warning{
  color: var(--yellow-7);
}


.alert-danger{
  background-color: rgb(242,222,222);
  color: rgb(169,68,67);
  border-color: rgb(169,68,67);
  border-style: solid;
  border-width: 1px;
  padding-block: 0.5em;
}

.alert-warning{
  background-color: var(--yellow-3);
  color: var(--yellow-7);
  border-color: var(--yellow-7);
  border-style: solid;
  border-width: 1px;
  padding-block: 0.5em;
  text-align: center;
}

.alert-warning > * {
  margin: 0;
}


.grid {
  display: grid;
}

.grid--auto-column {
  grid-auto-flow: column;
}

.i-center{
  place-items: center;
}

.grid--auto-row {
  grid-auto-flow: row;
}

.gap-xs{
gap: 0.5em;
}

.grid--gap{
  gap:1em
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex-wrap{
  flex-wrap: wrap;
}
.hide-me {
  display: none;
}

/* input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched {
  border: 1px solid #f0506e;
}

input.ng-valid.ng-touched,
select.ng-valid.ng-touched {
  border: 1px solid #6dcb99;
} */

.form-error {
  color: red;
}


.select-active{
  border: 2px solid red;
}


.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.text-input {
  width: 100%;
}

.glow-pulse {
  animation: glow-pulse 2s infinite;
  color: #ffcc00; /* Gold accent color */
}

@keyframes glow-pulse {
  0% {
    text-shadow: 0 0 10px #ffcc00, 0 0 20px #ffcc00, 0 0 30px #ffcc00, 0 0 40px #ffcc00;
  }
  50% {
    text-shadow: 0 0 20px #ffcc00, 0 0 40px #ffcc00, 0 0 60px #ffcc00, 0 0 80px #ffcc00;
  }
  100% {
    text-shadow: 0 0 10px #ffcc00, 0 0 20px #ffcc00, 0 0 30px #ffcc00, 0 0 40px #ffcc00;
  }
}
