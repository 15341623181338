.button {
  border-color: var(--button-border-color);
  border-width: 1px;
  border-style: solid;
  background-color: var(--button-bg);
  color: var(--button-txt-color);
  border-radius: 0.25em;
  align-self: center;
  cursor: pointer;
  font-size: 1rem;
  padding-inline: 1rem;
  padding-block:0.5rem;
  transition: background-color 100ms ease-in;
  display: inline;
}


.button--xs {
font-size: 0.25rem;
}

.button--sm {
  font-size: 0.5rem;
}

.button--md {
  font-size: 0.75rem;
}

.button--xl {
  font-size: 1.25rem;
}

.button--xxl {
  font-size: 1.5rem;
}

.button--full_width{
  width: 100%;
}

.button--brand {
  --button-bg: var(--brand-color);
  --button-border-color: white;
  --button-hover-bg: hsl(148, 47%, 40%);
}

.button--purple-fill {
  --button-bg: var(--light-purple);
  --button-border-color: var(--dark-purple);
  --button-hover-bg: var(--dark-purple);
}

.button--purple-light-fill {
  --button-bg: var(--white);
  --button-border-color: var(--dark-purple);
  --button-txt-color: var(--dark-purple);

  --button-hover-bg: var(--dark-purple);
}

.button--green {
  --button-bg: var(--white);
  --button-border-color: var(--light-green);
  --button-txt-color: var(--light-green);

  --button-hover-bg: var(--light-green);
}

.button--orange {
  --button-bg: var(--light-orange);
  --button-border-color: var(--dark-orange);
  --button-txt-color: var(--white);


  --button-hover-bg: var(--dark-orange);
}

.button--danger{
  --button-bg: var(--red-7);
  --button-border-color: var(--red-7);
  --button-hover-border-color: var(--red-9);
  --button-hover-bg: var(--red-9);
}

.button--blue {

  --button-bg: var(--light-blue);
  --button-border-color: var(--dark-blue);
  --button-txt-color: var(--white);
  --button-hover-bg: var(--dark-blue);

}

.button--blue-outline {
  --button-bg: var(--white);
  --button-border-color: var(--light-blue);
  --button-txt-color: var(--light-blue);

  --button-hover-bg: var(--dark-blue);

}

.button--black {
  --button-txt-color: var(--white);
  --button-bg: var(--dark-gray);
  --button-border-color: var(--light-gray);

  --button-hover-bg: var(--dark-green);
}

.button--black-outline {
  --button-txt-color: black;
  --button-bg: var(--white);
  --button-border-color: var(--dark-gray);

  --button-hover-txt-color: var(--white);
  --button-hover-bg: var(--dark-gray);
  --button-hover-border-color: var(--dark-gray);
}

.button:disabled
{
  --button-bg: var(--button-disabled-color);
  --button-hover-bg:var(--button-disabled-color);
  --button-border-color:var(--button-disabled-color);
  pointer-events: none;
  cursor: unset;
}



[type=button].disabled,
[type=button]:disabled{
  color:  var(--button-disabled-color);
  border-color:var(--button-disabled-color);
  cursor: not-allowed;
  pointer-events: none;
}



.button:active,
.button:hover {

  --button-bg: var(--button-hover-bg);
  --button-txt-color: var(--button-hover-txt-color);
  --button-border-color: var(--button-hover-border-color);

}

