.accordion{
  margin-inline-end: 10%;
  margin-block-start: 5%;
}

.accordion-item {
  position: relative;
  margin-block-end: 0.5rem;
}

.accordion-item .accordion-header {
  cursor: pointer;
  display: flex;
  border: 1px solid grey;
  padding: 0.25rem;
  border-radius: 0.25rem 0.25rem 0 0 ;
}

.accordion-header__button::after {
  content: '\276F';
  color: black;
  margin-left: auto;
  flex-shrink: 0;
  transform: rotate(90deg);
  transition: transform 0.5s ease;
  transform-origin: center;
  place-self: center center;
}
.accordion-header__button.active::after {
  transform: rotate(-90deg);
  color: blue;
}

.accordion-header__button {
  width: 100%;
  display: flex;
  text-align: start;
  background-color: white;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}

.accordion-header__button > * {
pointer-events: none;
cursor: pointer;
}

.accordion-header__button.active {
background-color: white;
color: blue;
}

.accordion-item .accordion-content{
  padding-inline:0.5rem;
  padding-block-start: 0.5rem;
  border-inline: 1px solid grey;
  border-bottom: 1px solid grey;
  overflow: none;
}

.accordion .accordion-content.closed {
  display: none;
}

.accordion .accordion-content.transitioning{
  height: 0;
  overflow: hidden;
  transition: height 500ms ease;
}

.accordion .accordion-content.open {
  border-width: 1px;
  display: block;

}
