:root{

  --light-purple: var(--grape-7);
  --dark-purple: var(--grape-9);

  --light-red: var(--red-7);
  --dark-red: var(--red-9);

  --light-green: var(--green-5);
  --dark-green: var(--green-8);

  --light-blue: var(--blue-4);
  --dark-blue: var(--blue-5);

  --light-orange: var(--yellow-6);
  --dark-orange: var(--yellow-7);

  --very-light-gray: var(--gray-3);
  --light-gray: var(--gray-7);
  --dark-gray: var(--gray-8);

  --brand-color: #6DCB99;

  --white: white;

--card-background-color: #f7f9fa;
  --card-decoration-color: var(--light-orange);

  --button-size:  var(--size-8);
  --button-hover-bg: white;
  --button-hover-txt-color: white;
  --button-hover-border-color: white;
  --button-bg: var(--light-purple);
  --button-border-color: var(--dark-purple);
  --button-txt-color: var(--white);
  --button-disabled-color: #adc1bd;


}
