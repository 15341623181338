.dialog-header{
  display: grid;
  justify-content: center;
  align-items: center;
}
.dialog-container{

  display: grid;
  grid-auto-rows: 0.25fr 1fr 0.25fr;
  gap:0.25em;
  width: 100%;
  height: 100%;
}
