@import url("./Styles/variables.css");
@import url("../node_modules/uikit/dist/css/uikit.min.css");
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("../node_modules/sidebar-v2/css/ol3-sidebar.css");
@import url("../node_modules/ol/ol.css");
@import url("../node_modules/fontawesome-free/css/all.css");
@import url("../node_modules/open-props/open-props.min.css");
@import url("./Styles/accordion.css");
@import url("./Styles/geoCoding.css");
@import url("./Styles/reporting-dialogs.css");
@import url("./Styles/map.css");
@import url("./Styles/buttons.css");
@import url("./Styles/card.css");
@import url("./Styles/popover.css");
@import url("./Styles/esk-toolbar.css");
@import url("./Styles/dialog.css");
@import url("./Styles/utilities.css");
@import url("./Styles/esk-tooltip.css");
@import url("./Styles/overrides.css");



html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: var(--font-weight-5);
  background-color: var(--gray-2);
}

.full-screen-container {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.app-container {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 3em auto 150px;
  grid-template-areas:
    "header header header header"
    ".... main main ...."
    "footer footer footer footer";
}




.dashboard-container {
  width: 100%;
  display: grid;
  gap: 2em;
  grid-template-areas:
    "alert"
    "title"
    "user-details"
    "manage-property"
    "manage-pad"
    "tools"
    "news";
}



.user-details {
  display: grid;
  gap: 1em;
  padding: 1em;
  grid-template-areas:
    "card-title icon"
    "user-links user-links";
}

.user-details-container {
  grid-area: user-details;
}

.manage-property {
  display: grid;
  gap: 1em;
  padding: 1em;
  grid-template-areas:
    "card-title icon"
    "select-prop select-prop"
    "add-prop add-prop"
    "manage-prop manage-prop";
}

.manage-property-container {
  grid-area: manage-property;
}

.manage-property__select-container {
  grid-area: select-prop;
  display: grid;
  justify-content: center;
}

.manage-property__select {
  min-width: var(--size-13);
  width: 100%;
  overflow: hidden;
  border: 1px solid black;
  border-radius: var(--radius-2);
  padding: var(--size-1);
  text-align: center;
}

.manage-pad {
  display: grid;
  gap: 1em;
  padding: 1em;
  grid-template-areas:
    "card-title icon"
    "map-tool map-tool"
    "report-tool report-tool";
}

.manage-pad-container {
  grid-area: manage-pad;
}

.news {
  padding: 1em;
  display: grid;
  gap: 1em;
  grid-template-areas:
    "card-title icon"
    "news-item-one news-item-one"
    "news-item-two news-item-two";
}

.news-container {
  grid-area: news;
}

.news_container {
  text-align: center;
}

.news_item-one {
  grid-area: news-item-one;
}

.news_item-two {
  grid-area: news-item-two;
}

.tools-container {
  grid-area: tools;
}

.tools {
  display: grid;
  gap: 1em;
  padding: 1em;
  grid-template-columns: 1fr;
  grid-template-areas:
    "card-title icon"
    "dss-tool dss-tool"
    "f-dsc-tool f-dsc-tool"
    "f-woodlot-tool f-woodlot-tool"
    "t-score-tool t-score-tool"
    "f-man-scen-tool f-man-scen-tool";
}

.button--crop-tool {
  grid-area: dss-tool;
}

.button--forest-tool {
  grid-area: f-dsc-tool;
}

.button--woodlot-tool {
  grid-area: f-woodlot-tool;
}

.button--topex-tool {
  grid-area: t-score-tool;
}

.button--npv-tool {
  grid-area: f-man-scen-tool;
}

.title {
  grid-area: title;
  display: flex;
  place-self: center;
}

.title-text {
  border-bottom: solid orange 2px;
}

.dashboard-alert {
  background-color: #f4e8e9;
  border: solid #82353d 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dashboard-alert-container {
  grid-area: alert;
}

.dashboard-alert__icon {
  place-self: center start;
  margin: 0;
}

.user-detials__list {
  grid-area: user-links;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: large;
}

.user-detials__list li {
  display: inline-block;
}

.user-detials__list li:nth-child(2) {
  padding-left: 1em;
}

.user-detials__link {
  text-decoration: none;
  color: black;
  border-bottom: solid orange 2px;
}

.user-detials__link--red {
  border-bottom: solid orange 2px;
}

.user-detials__link--purple {
  border-bottom: solid #886893 2px;
}

.user-detials__link--blue {
  border-bottom: solid #88aae3 2px;
}

.user-detials__link--light-green {
  border-bottom: solid #6dcb99 2px;
}

.user-detials__link--green {
  border-bottom: solid #4b7902 2px;
}

.user-detials__link--orange:active {
  color: orange;
  text-decoration: none;
}

.user-detials__link--purple:hover {
  color: #886893;
  text-decoration: none;
}

.user-detials__link--blue:hover {
  color: #88aae3;
  text-decoration: none;
}

.user-detials__link--light-green:hover {
  color: #6dcb99;
  text-decoration: none;
}

.user-detials__link--green:hover {
  color: #4b7902;
  text-decoration: none;
}

.user-detials__list:active {
  color: orange;
  text-decoration: none;
}

.button--map-tool {
  grid-area: map-tool;
}

.button--add-prop {
  grid-area: add-prop;
}

.button--manage-prop {
  grid-area: manage-prop;
}

.button--report-tool {
  grid-area: report-tool;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 800px) {
  .dashboard-container {
    margin: 1em;
    grid-template-columns: minmax(150px, 300px) minmax(350px, 600px);
    grid-template-rows: minmax(50px, 50px) minmax(100px, 150px) auto auto;
    grid-template-areas:
      "title alert alert ...."
      "user-details manage-pad manage-pad ...."
      "manage-property tools tools ...."
      ".... news news ....";
  }

  .user-details {
    grid-template-areas:
      "card-title icon"
      "user-links user-links";
  }

  .manage-pad {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "card-title icon"
      "map-tool report-tool";
  }

  .tools {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "card-title icon"
      "dss-tool f-dsc-tool"
      "f-woodlot-tool t-score-tool"
      "f-man-scen-tool f-man-scen-tool";
  }

  .title {
    place-self: center start;
  }

  /* User Details card */
  .user-detials__list li {
    display: list-item;
  }

  .user-detials__list li:nth-child(2) {
    padding-left: 0;
  }

  .news {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "card-title icon"
      "news-item-one news-item-two";
  }

  /* Button Styles*/

  .user-detials__list:hover {
    color: orange;
    text-decoration: none;
  }

  .user-detials__link--orange:hover {
    color: orange;
    text-decoration: none;
  }
}

html {
  font-size: 16px;
  color: black;
}


/* NVP Calculator */
@media (max-width: 1440px) {
  #npvCalculator {
    /* max-height: 100vh;
    min-height: 100vh;
    overflow-y: scroll; */
    width: 90vw;
    /* min-height: 100vh; */
  }
}

@media (min-width: 1441px) {
  #npvCalculator {
    /* max-height: 100vh;
    min-height: 100vh;
    overflow-y: scroll; */
    max-width: 1440px;
    min-width: 1440px;
  }
}

label.fw-label.vertically-center-them {
  align-items: center;
}

html,
body {
  height: 100%;
}

.footer-container {
  /* position: absolute; */
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;

}

ngb-tooltip-window::after {
  content: '';
  position: absolute;
  left: calc(50% - 10px);
  top: 100%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(0 0 0 / 75%);
  clear: both;
}

ngb-tooltip-window[role="tooltip"] {
  background: rgb(0 0 0 / 75%);
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 10px !important;
}


.error-snackbar {
  background-color: #f44336; /* Red background */
  color: #ffffff; /* White text color */
  text-align: center;
}

.success-snackbar {
  background-color: #4caf50; /* Green background */
  color: #ffffff; /* White text color */
}

.centered-text-snackbar .mat-simple-snackbar {
  display: flex;
  justify-content: center;
}


h2 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--brand-color);
}
